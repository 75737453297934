$background:#f5f6fd;
$color:#0a1f44;

/*Color variables*/

$primary1:#3b62fe;
$primary2:#754bfc;
$primary3:#7d22a1;
$primary4:#4e46b7;
$primary5:#3f9a77;
$primary6:#6b66f8;
$primary7:#8133ff;
$primary8:#692e66;
$primary9:#05addc;
$primary10:#845ffb;

$secondary:#fb5c3b;
$secondary2:#ff5da7;
$secondary4:#05b0b5;
$secondary5:#eca21c;
$secondary8:#ec3a71;
$secondary10:#ec6220;

$info:#0b94f7;
$success:#01d277;
$warning:#fb9505;
$danger:#f84242;
$blue:#096cf7;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#8b3ce0;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/

$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
